<template>
  <v-container style="text-align: center; justify-content: center;" :style="enableMargins ? '' : 'max-width: 100% !important;'">
    <v-row>
      <v-col cols="6" style="text-align: right;"><h1>Submissão de Exames</h1></v-col>
      <v-col cols="6" style="text-align: left;"><v-checkbox v-model="enableMargins" label="Habilitar Margens" color="accent"/></v-col>
      <v-col cols="12" v-if="isPossibleRecovery">
        Você tem uma prova salva. Clique para
        <v-btn @click="recoverFromSave" text color="accent" class="text-capitalize">Recuperar Exame</v-btn>
      </v-col>
    </v-row>
    <v-row :class="enableMargins ? 'row-margin' : ''">
      <v-col :class="enableMargins ? 'card-margin' : ''">
        <v-card>
          <v-card-title>Descrição do Exame</v-card-title>
          <v-card-text>
            <i v-if="!getInstitutions">Carregando...</i>
            <v-form v-else ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="4">
                  <v-select v-model="Institution" :items="Object.keys(getInstitutions)" :rules="[v => !!v || 'Escolha uma instituição']"
                            label="Instituição" color="accent" item-color="accent" solo required clearable></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="Year" label="Ano" @keypress="isPositiveInteger($event)"
                                :rules="[v => (v > 1940 && v < 2023) || 'Digite um ano válido']"
                                color="accent" solo required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select v-model="Phase" :items="phaseNames" :rules="[v => !!v || 'Escolha uma fase']"
                            label="Fase" color="accent" item-color="accent" solo required></v-select>
                </v-col>
              </v-row>
              <v-row dense>
<!--                <v-col cols="6">-->
<!--                  <v-select v-model="Subject" :items="Object.keys(getSubjects)" label="Disciplina" color="accent"-->
<!--                            :rules="[v => !!v || 'Escolha uma disciplina']" item-color="accent"-->
<!--                            hint="Cada exame deve ter uma disciplina" solo required></v-select>-->
<!--                </v-col>-->
<!--                <v-col cols="6">-->
                <v-col cols="12">
                  <v-select v-if="Subject && !!getComplements[Subject]" clearable
                            v-model="subjectComplement" label="Parte (preencha com muito cuidado!)"
                            color="accent" style="text-decoration-color: white !important;"
                            :items="Object.keys(getComplements[Subject])" item-color="accent"
                            hint="Confira essa informação com os admins. 'Parte' significa qual pedaço da prova você vai transcrever."
                            persistent-hint solo></v-select>
                  <v-select v-else disabled label="Parte (preencha com muito cuidado!)" color="accent" solo></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="valid">
      <v-row :class="enableMargins ? 'row-margin' : ''">
        <v-col :class="enableMargins ? 'card-margin' : ''">
          <v-card>
            <v-card-title>Questões</v-card-title>
            <v-card-text>
              <v-form ref="addQForm" v-model="addQValid">
                <v-row dense style="overflow: hidden;">
                  <v-col cols="3">
                    <v-select v-model="questionAddType" label="Tipo de Questão" color="accent" item-color="accent"
                              :items="['Objetiva', 'Discursiva', 'Certo/Errado']" :rules="[v => (!!v)]"
                              @change="questionAddType==='Objetiva'?questionAddNumberItems=5:questionAddNumberItems=0"></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model.number="questionAddNumber" label="Número de Questões" color="accent"
                                  :rules="[v => (v>0 && !isNaN(v))]" type="number" autocomplete="off"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model.number="questionAddNumberItems" :disabled="questionAddType==='Certo/Errado'"
                                  :label="questionAddType==='Objetiva'?'Alternativas por questão':'Número de Itens por questão'"
                                  color="accent" :rules="[v => (v===0 || (v>0 && !isNaN(v) && v<27))]"
                                  type="number" autocomplete="off"></v-text-field>
                  </v-col>
                  <v-col cols="3" style="align-self: center;">
                    <v-btn small depressed class="ignored-button" color="accent" @click="addQuestionsStart"
                           style="width: 49%; font-size: x-small; margin-right: 1%;" :disabled="!addQValid">+ No Início</v-btn>
                    <v-btn small depressed class="ignored-button" color="accent" @click="addQuestionsEnd"
                           style="width: 49%; font-size: x-small; margin-left:  1%;" :disabled="!addQValid">+ No Final</v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-form ref="removeQForm" v-model="removeQValid">
                <v-row dense style="overflow: hidden;">
                  <v-col cols="5">
                    <v-text-field v-model.number="questionRemoveNumber" label="Número de Questões para Remoção"
                                  :rules="[v => (v>0 && !isNaN(v) && v<=questionNumber)]" color="accent"
                                  type="number" autocomplete="off"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select v-model="questionRemoveOrder" label="Posição" color="accent"
                              :items="['Do início', 'Do final']" :rules="[v => (!!v)]"></v-select>
                  </v-col>
                  <v-col cols="3" style="align-self: center;">
                    <v-btn block small depressed color="error" @click="RemoveQuestions"
                           class="ignored-button" style="font-size: small;" :disabled="!removeQValid">Remover</v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-row dense v-if="questionSummary.length">
                <v-col cols="12" style="text-align: left;" class="text--primary">
                  <span><b>Resumo: </b>{{questionSummary}}</span><br>
                  <span><b>Atalhos de teclado: </b>CTRL + Q = Alternar modo de edição</span>
                </v-col>
              </v-row>
              <v-row dense justify="center" align="stretch" v-for="(question, index) in Questions" :key="question.keyId">
                <v-col cols="12" style="position: relative">
                  <v-btn style="position: absolute; right: 0; top: 0;" icon @click="toggleSingleEdit(index)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn style="position: absolute; right: 0; top: 40px;" icon @click="toggleSingleCollapse(index)">
                    <v-icon>{{ isCollapsed[index] ? 'mdi-window-maximize' : 'mdi-window-minimize' }}</v-icon>
                  </v-btn>
                  <edit-question-submission :ref="`question-${index}`" :question-meta="question" :exam-meta="examMeta" :question-number="index"
                                            :saved-data="questionsBackupData[`question-${index}`]" :is-collapsed="isCollapsed[index]"
                                            :edit-mode="isEditing[index]" :external-gabarito="externalGabaritos[index]"
                                            @toggle-disc="changeQuestionType(index, ...arguments)" @toggle-ce="changeCEFlag(index, ...arguments)"
                                            @set-item="setNumItems(index, ...arguments)" @increment-item="changeNumItems(index, ...arguments)"
                                            @question-mounted="setQuestionUploadFunction" @submit-q-object="getQuestionObject"
                                            style="height: 99%; text-align: initial;" :style="enableMargins ? 'width: 90%' : 'width: calc(100% - 40px)'"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row :class="enableMargins ? 'row-margin' : ''">
        <v-col :class="enableMargins ? 'card-margin' : ''">
          <v-card>
            <v-card-title>Gabarito</v-card-title>
            <v-card-text>
              <v-form ref="gabaritosForm" v-model="gabaritosValid">
                <v-row dense>
                  <v-col cols="4" xl="2" lg="2" md="3" sm="3" v-for="(question, qIdx) in Questions" :key="question.keyId">
                    <v-text-field v-model="externalGabaritos[qIdx]" @input="setGabarito(qIdx)" :disabled="question.disc"
                                  :rules="[v => (!v||(!!v && v.length===2 && alphabet.slice(0, question.isCE?2:question.numItems).includes(v)))]"
                                  :label="`Q${qIdx+1}`+(question.disc?' - Discursiva':'')" solo color="accent"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense><v-col cols="12">
                  <i>Cada gabarito deve conter apenas a alternativa correta, a) b) c) d) ou e), em letra minúscula.</i><br>
                  <i>O gabarito de questões anuladas deve ficar em branco.</i>
                </v-col></v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row :class="enableMargins ? 'row-margin' : ''">
        <v-col :class="enableMargins ? 'card-margin' : ''">
          <v-card>
            <v-card-title>Submissão</v-card-title>
            <v-card-text>
              <v-btn large color="accent" @click="createExam" :disabled="!valid || !Questions.length || !gabaritosValid || isUploading">Submeter</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template v-if="Questions.length">
      <!-- Save FAB -->
      <v-btn @click="setNewSave" color="success" fab small fixed style="bottom: 110px; right: 20px;">
        <v-icon>mdi-content-save-all</v-icon>
      </v-btn>
      <!-- Collapse FAB -->
      <v-btn @click="toggleCollapse" color="accent" fab small fixed style="bottom: 50px; right: 20px;">
        <v-icon>{{ isCollapsed[0] ? 'mdi-window-maximize' : 'mdi-window-minimize' }}</v-icon>
      </v-btn>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import EditQuestionSubmission from '@/components/EditQuestionSubmission';
export default {
  name: 'IDE',
  components: {EditQuestionSubmission},
  data: () => ({
    enableMargins: true,
    valid: false, addQValid: false, removeQValid: false, gabaritosValid: false,
    Institution: null,
    Year: null,
    Phase: null,
    Subject: 'Placeholder', // Do this just for PAS-UNB
    subjectComplement: null,
    phaseNames: ['Fase única'], // Do this just for PAS-UNB , 'Primeira Fase', 'Segunda Fase', 'Terceira Fase'],

    questionsBackupData: {},
    Questions: [],
    questionNumber: 0,
    isEditing: [],
    isCollapsed: [],
    currentKeyId: 0, // Solves problem https://deepsource.io/blog/key-attribute-vue-js/
    questionUploadFunctions: [],
    questionObjects: {},

    questionAddType: null,
    questionAddNumber: null,
    questionAddNumberItems: null,
    questionRemoveNumber: null,
    questionRemoveOrder: null,

    externalGabaritos: [],

    isUploading: false,
    alphabet: ['a)', 'b)', 'c)', 'd)', 'e)', 'f)', 'g)', 'h)', 'i)', 'j)', 'k)', 'l)', 'm)', 'n)',
      'o)', 'p)', 'q)', 'r)', 's)', 't)', 'u)', 'v)', 'w)', 'x)', 'y)', 'z)']
  }),
  computed: {
    ...mapGetters(['getInstitutions', 'getSubjects', 'getComplements', 'getPendingExams', 'getCompletedExams']),
    examMeta() { return { institution: this.Institution || '', year: this.Year || '', subject: this.Subject || '' } },
    questionSummary() {
      let isLastQuestionDisc = null; let summary = []; let groupIndex = 0;
      for (let q of this.Questions) {
        const qType = q.disc?'Discursivas':'Objetivas';
        if (q.disc !== isLastQuestionDisc) { groupIndex += 1; summary.push([1, qType]); isLastQuestionDisc = q.disc; }
        else { summary[groupIndex-1] = [summary[groupIndex-1][0] + 1, qType] }
      } return summary.map(group => group.join(' ')).join(' + ');
    },
    isPossibleRecovery() { return !this.Questions.length && localStorage['examBackup'] && localStorage['questionsData']; },
  },
  watch: {
    Subject: function(){this.subjectComplement = null},
    Questions: function(qArr){
      this.isEditing = Array(qArr.length).fill(true);
      this.isCollapsed = Array(qArr.length).fill(false);
    },
  },
  mounted() {
    this._listenEditMode = (e) => { if (e.key === "q" && (e.ctrlKey || e.metaKey)) { e.preventDefault(); this.toggleEditMode(); } };
    document.addEventListener('keydown', this._listenEditMode.bind(this));
  },
  beforeDestroy() { document.removeEventListener('keydown', this._listenEditMode); },
  methods: {
    setGabarito(i) { if (this.externalGabaritos[i].length === 1) { this.externalGabaritos[i] += ')'} else { this.externalGabaritos[i] = null } },
    changeNumItems(i, val) { this.Questions[i].numItems += val; },
    setNumItems(i, val) { this.Questions[i].numItems = val; },
    changeCEFlag(i, val) { this.Questions[i].isCE = val; },
    changeQuestionType(i, val) { this.Questions[i].disc = val; },
    toggleSingleEdit(i) {
      this.$set(this.isCollapsed, i, false);
      const oldVal = this.isEditing[i]; this.$set(this.isEditing, i, !oldVal);
      if (oldVal && ('MathJax' in window)) { this.$nextTick(function() { window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.body]) }) }
    },
    toggleEditMode() {
      this.isCollapsed = Array(this.Questions.length).fill(false);
      if (this.isEditing[0]) {
        this.isEditing = Array(this.Questions.length).fill(false);
        if ('MathJax' in window) { this.$nextTick(function() { window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.body]) }) }
      } else{ this.isEditing = Array(this.Questions.length).fill(true); }
    },
    toggleSingleCollapse(i) { this.$set(this.isCollapsed, i, !this.isCollapsed[i]); },
    toggleCollapse() {
      if (this.isCollapsed[0]) { this.isCollapsed = Array(this.Questions.length).fill(false); }
      else{ this.isCollapsed = Array(this.Questions.length).fill(true); }
    },
    setQuestionUploadFunction(fn) {
      this.questionUploadFunctions.push(fn);
      Object.assign(this.questionsBackupData, {});
    },
    isPositiveInteger(e) {
      const charCode = (typeof e.which === "number") ? e.which : e.keyCode,
        chr = String.fromCharCode(charCode);
      if(isNaN(parseInt(chr, 10))) e.preventDefault();
    },
    // clearForm() { this.$refs.form.reset(); this.questionNumber=0; this.Questions=[]},
    addQuestionsEnd() {
      for (let i=0; i<this.questionAddNumber; i++) {
        this.Questions.push({
          disc: this.questionAddType==='Discursiva',
          isCE: this.questionAddType==='Certo/Errado',
          numItems: this.questionAddNumberItems,
          keyId: this.currentKeyId,
        }); this.currentKeyId++;
      } this.questionNumber += this.questionAddNumber; this.$refs.addQForm.reset();
    },
    addQuestionsStart() {
      for (let i=0; i<this.questionAddNumber; i++) {
        this.Questions.unshift({
          disc: this.questionAddType==='Discursiva',
          isCE: this.questionAddType==='Certo/Errado',
          numItems: this.questionAddNumberItems,
          keyId: this.currentKeyId,
        }); this.currentKeyId++;
      } this.questionNumber += this.questionAddNumber; this.$refs.addQForm.reset();
    },
    RemoveQuestions() {
      if (this.questionRemoveOrder === 'Do final') {
        for (let i=0; i<this.questionRemoveNumber; i++) { this.Questions.pop(); this.questionUploadFunctions.pop(); }
      } else { for (let i=0; i<this.questionRemoveNumber; i++) { this.Questions.shift(); this.questionUploadFunctions.shift(); } }
      this.questionNumber -= this.questionRemoveNumber; this.$refs.removeQForm.reset();
    },
    getQuestionObject(obj) { this.questionObjects[obj.id] = obj.data; },
    setNewSave() {
      try {
        const questionsData = {};
        const properties = ['alternativesModel', 'isPendingImages', 'newLink', 'questionAlternativas', 'questionCEFlag',
          'questionDados', 'questionEnunciados', 'questionGabaritos', 'questionSwitch', 'questionTexto', 'textOverlay', 'valid'];
        for (let i=0; i<this.Questions.length; i++) {
          questionsData[`question-${i}`] = {};
          for (let prop of properties) {
            questionsData[`question-${i}`][prop] =  this.$refs[`question-${i}`][0].$data[prop];
          }
        }
        localStorage.setItem('examBackup', JSON.stringify(this.$data));
        localStorage.setItem('questionsData', JSON.stringify(questionsData));
        alert('Dados guardados localmente com sucesso!');
      } catch (e) { alert(`Ocorreu um erro: ${JSON.stringify(e)}`); }
    },
    recoverFromSave() {
      try {
        Object.assign(this.$data, JSON.parse(localStorage.getItem('examBackup')));
        Object.assign(this.questionsBackupData, JSON.parse(localStorage.getItem('questionsData')));
        this.isUploading = false; this.questionUploadFunctions = [];
      } catch (e) { alert(`Ocorreu um erro: ${e}`); }
    },
    async createExam() {
      if (this.isUploading) { return false } else { this.isUploading = true; }
      this.$store.commit("enableLoadingState");
      try {
        let examID = "";
        let examTitle= "";
        if (this.subjectComplement) {
          examID = parseInt(
            this.getInstitutions[this.Institution] + String(this.Year) + String(this.phaseNames.indexOf(this.Phase))
            + this.getSubjects[this.Subject] + this.getComplements[this.Subject][this.subjectComplement] ).toString(36);
          examTitle = `${this.Institution} ${this.Year} ${this.Phase} - ${this.Subject} (${this.subjectComplement})`;
        } else {
          examID = parseInt(
            this.getInstitutions[this.Institution] + String(this.Year) + String(this.phaseNames.indexOf(this.Phase))
            + this.getSubjects[this.Subject] + "0" ).toString(36);
          examTitle = `${this.Institution} ${this.Year} ${this.Phase} - ${this.Subject}`;
        }

        for (let uploadFn of this.questionUploadFunctions) { uploadFn(examID); }

        let examStructure = [];
        for (let qNum = 0; qNum<this.questionNumber; qNum++) {
          examStructure.push([`Questão ${qNum+1}`, []]);
          if (this.Questions[qNum].disc) {
            for (let iNum = 0; iNum < this.Questions[qNum].numItems; iNum++) {
              examStructure[qNum][1].push(this.alphabet[iNum]);
            }
          }
        }

        if (!Object.prototype.hasOwnProperty.call(this.getPendingExams, examID) && !Object.prototype.hasOwnProperty.call(this.getCompletedExams, examID)){
          let examData = {
            institution: this.Institution,
            year: this.Year,
            phase: this.Phase,
            subject: this.Subject,
            complement: this.subjectComplement,
            structure: JSON.stringify(examStructure)
          };
          await this.$store.dispatch('uploadNewExamFromIDE', {
              newExamID: examID, newExamTitle: examTitle, newExamData: examData,
              newQuestions: this.questionObjects,
              uid: this.$store.state.auth.user.uid,
              uname: this.$store.state.auth.user.displayName
            }
          );
          localStorage.removeItem('examBackup'); localStorage.removeItem('questionsData'); // Remove saved data
          this.$router.push('/submissoes').catch(()=>{});
        } else { this.isUploading = false; alert('Este exame já existe no banco de dados'); }
      } catch (e) { alert(`Ocorreu um erro! Entre em contato conosco: ${e}`); console.log(e); }
      finally { this.$store.commit('disableLoadingState'); this.isUploading = false; }
    }
  }
}
</script>

<style scoped>
.ignored-button { opacity: 0.5; }
.ignored-button:hover { opacity: 1; }
.row-margin { margin-right: 5%; margin-left: 5%; }
.card-margin { text-align: center; text-align: -webkit-center; }
@media screen and (min-width: 1264px){
  .card-margin { padding-right: 10%; padding-left: 10%; }
}
</style>
